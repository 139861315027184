import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import b from 'b_';
import Icon from '../Icon/Icon';
import { FTBody, FTHeading } from '../Fuse';
import FuseButton from './FuseButton';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './FuseAlert.css';
import './FuseAlert.new.css';

const oldBlock = b.lock('FuseAlert');
const newBlock = b.lock('Fuse5Alert');

export default class FuseAlert extends Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.oneOf(['danger', 'warning', 'success', 'info']).isRequired,
        title: PropTypes.node,
        subtitle: PropTypes.node,
        icon: PropTypes.string,
        iconClassName: PropTypes.string,
        centerIcon: PropTypes.bool,
        collapsible: PropTypes.bool,
        closeButton: PropTypes.bool,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        type: 'warning',
        centerIcon: false,
        collapsible: false,
    };

    state = {
        isOpen: true,
    };

    defaultIconByType = isFuse5Enabled() ? {
        success: 'check_circle_rounded',
        warning: 'warning',
        danger: 'error',
        info: 'info',
    } : {
        success: 'check',
        warning: 'info',
    };

    render() {
        const {
            className, type, title, subtitle,
            icon, iconClassName, centerIcon, collapsible, closeButton,
            children, ...otherProps
        } = this.props;
        const { isOpen } = this.state;
        const block = isFuse5Enabled() ? newBlock : oldBlock;

        return <div className={cn(block({ type }), className)} {...otherProps}>
            <div className={block('IconWrapper')}>
                {isFuse5Enabled() ? <Icon
                    className={cn(block('Icon', { center: centerIcon, type }), iconClassName)}
                    type={icon || this.defaultIconByType[type] || 'warning'}
                /> : <Icon
                    className={cn(block('Icon', { center: centerIcon }), iconClassName)}
                    type={icon || this.defaultIconByType[type] || 'warning'}
                />}
            </div>
            <div className={block('ContentWrapper')}>
                {collapsible ? <FuseButton
                    className={block('Toggle')}
                    icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    onClick={() => this.setState({ isOpen: !isOpen })}
                /> : null}

                {title || subtitle ? <div className={block('Header', { collapsible })}>
                    {title ? <FTHeading className={block('Title')}>{title}</FTHeading> : null}
                    {subtitle ? <FTBody className={block('Subtitle')}>{subtitle}</FTBody> : null}
                </div> : null}

                {this.state.isOpen && children ? <FTBody className={block('Content')}>
                    {children}
                </FTBody> : null}
            </div>
            {isFuse5Enabled() && closeButton ?
                <div className={block('IconWrapper')}>
                    <FuseButton
                        className={block('closeButtonIcon')}
                        iconRight="close"
                        onClick={this.props.onClose}
                    />
                </div> : null}
        </div>;
    }
}
