import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import b_ from 'b_';
import { TTitle } from '../Text/Text';
import Button from '../Button';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './Box.css';
import './Box.new.css';

let block = b_.lock('Box');
const newBlock = b_.lock('Fuse5Box');

export default function Box({
    className,
    titleClassName,
    contentClassName,
    title,
    children,
    onClose,
    theme = 'default',
    noPadding,
    ...otherProps
}) {
    block = isFuse5Enabled() ? newBlock : block;
    return <div
        className={cn(block({ theme }), className)}
        {...otherProps}
    >
        {title ? <TTitle className={cn(block('Title'), titleClassName)}>
            {title}
            {onClose ? <Button className={block('Close')} raised closeBox onClick={onClose} icon="close" /> : null}
        </TTitle> : null}
        <div className={cn(block('Content', { noPadding }), contentClassName)}>{children}</div>
    </div>;
}

Box.propTypes = {
    title: PropTypes.node,
    theme: PropTypes.string,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    onClose: PropTypes.func,
    noPadding: PropTypes.bool,
};
