import { IS_EXTENSION } from './env';


const fallback = {};

const storageCall = IS_EXTENSION ? () => {} : (method, ...args) => {
    try {
        return window.localStorage[method](...args);
    } catch (e) { /* whatever */ }
};

const LocalStorage = {
    getStorage: () => (IS_EXTENSION ? fallback : (window.localStorage || {})),

    getRaw(key) {
        const value = storageCall('getItem', key);
        if (value !== undefined) {
            return value;
        }
        if (fallback[key] !== undefined) {
            return fallback[key];
        }
        return null;
    },

    setRaw(key, value) {
        storageCall('setItem', key, value);
        fallback[key] = value;

        const keyAudit = `${key}_lastModified`;
        const now = new Date().toISOString();
        storageCall('setItem', keyAudit, now);
        fallback[keyAudit] = now;
    },

    get(key, defaultValue = null) {
        try {
            const raw = this.getRaw(key);
            return raw === null ? defaultValue : JSON.parse(raw);
        } catch (e) { /* whatever */ }
        return defaultValue;
    },

    set(key, value) {
        try {
            this.setRaw(key, JSON.stringify(value));
        } catch (e) { /* whatever */ }
    },

    remove(key) {
        storageCall('removeItem', key);
        storageCall('removeItem', `${key}_lastModified`);
        delete fallback[key];
    }
};

export default LocalStorage;

export const LS_KEY = {
    adBlock: 'BrowserStore.adBlock',
    webmailProvidersVisits: 'BrowserStore.webmailProvidersVisits',
    lastBrowserReserveApiCall: 'BrowserStore.lastBrowserReserveApiCall',
    researchMode: 'ResearchStore.researchMode',
    researchLocation: 'ResearchStore.location',
    researchUserAgent: 'ResearchStore.userAgent',
    recentlyLoadedUrls: 'recentlyLoadedUrls', // for onlyWebmail filter
    previousSessionId: 'BrowserStore.previousSessionId',
    clientConfigWebp: 'clientConfig.webp',
    clientConfigAvif: 'clientConfig.avif',
    clientConfigUserAgent: 'clientConfig.userAgent',
    clientConfigUserAgentData: 'clientConfig.userAgentData',
    enableDebugEvents: 'clientConfig.enableDebugEvents',
    disableWindowSizeChange: 'clientConfig.disableWindowSizeChange',
    debugPointerEvents: 'clientConfig.debugPointerEvents',
    consoleNavigation: 'ConsoleStore.navigation',
    consoleNavigationDashboard: 'ConsoleStore.navigationDashboardToggle',
    consoleNavigationUD: 'ConsoleStore.navigationUDToggle',
    consoleNavigationBrowser: 'ConsoleStore.navigationBrowserToggle',
    consoleNavigationPolicies: 'consoleNavigationPolicies',
    consoleNavigationNewPolicies: 'consoleNavigationNewPolicies',
    consoleNavigationProductSettings: 'consoleNavigationProductSettings',
    consoleNavigationAdministrators: 'consoleNavigationAdministrators',
    consoleNavigationCustomizeUI: 'consoleNavigationCustomizeUI',
    consoleNavigationPinned: 'consoleNavigationPinned',
    consoleV2Reporting: 'consoleV2Reporting',
    udBlockPage: 'BrowserStore.blockPage',
    topBarMinimizable: 'isTopBarMinimizable', // inside the browser store, for user settings and forced expanded top bar
    troubleshootingCaseId: 'BrowserStore.troubleshootingCaseId',
    adminTimeoutReset: 'AdminTimeoutStore.reset',
    fileManagerBlockedFilesEvents: 'fileManagerStore.blockedFilesEvents',
    fileManagerLastFileEvent: 'fileManagerStore.lastFileEvent',
    isAccessCodeApplyMode: 'BrowserStore.isAccessCodeApplyMode',
    userGesture: 'botDetector.userGesture',
    superAllowCustomerChanges: 'superAllowCustomerChanges',
    superNavigationAdministrators: 'superNavigationAdministrators',
    previousBrowserId: 'BrowserStore.browserId',
    shadowRootDebug: 'shadowRootDebug',
    noPendo: 'noPendo',
    syncXhrSupported: 'syncXhrSupported',
    debugCanvas: 'debugCanvas',
    themeColorScheme: 'themeColorScheme',
};
