import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import BrowserStore from '../../browser/stores/BrowserStore';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import FuseButton from '../FuseControls/FuseButton';
import EnvStore from '../../stores/EnvStore';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import FuseAlert from '../FuseControls/FuseAlert';
import './BannerNotification.css';
import './BannerNotification.new.css';


let block = b.lock('BannerNotification');
const newblock = b.lock('Banner5Notification');

class BannerNotification extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
    };

    [BrowserStore.NOTIFICATION.ENTER_ISOLATION_MESSAGE] = () => {
        const { t, browser, env } = this.props;
        let enterMsg = t('UDTopBar.enterIsoText');
        if (browser.enterIso?.content && browser.enterIso?.option !== 'DEFAULT') {
            enterMsg = <FuseWysiwygReadOnly content={browser.enterIso?.content} />;
        }
        block = isFuse5Enabled() ? newblock : block;
        return (
            isFuse5Enabled() ?
                <FuseAlert
                    className={cn(block('bannerbody'))}
                    type="info"
                    closeButton
                    onClose={browser.closeNotification}
                >{enterMsg}
                </FuseAlert> :
                <div className={cn(block())}>
                    <div className={cn(block('banner-icon'))} >
                        <FuseButton
                            iconRight="info_outline"
                            iconRightClassName={cn(block('info-icon'))}
                            tabIndex={-1}
                        />
                    </div>
                    <div className={cn(block('bannerbody', { mobile: env.screen.isMobile }))}>{enterMsg}</div>
                    <div className={cn(block('banner-icon-close'))}>
                        <FuseButton
                            iconRight="close"
                            onClick={() => browser.closeNotification()}
                        />
                    </div>
                </div>
        );
    };

    renderDropdown() {
        const notification = this.props.browser.notification;
        if (notification && this[notification]) {
            return this[notification]();
        }
        return null;
    }

    render() {
        return this.renderDropdown();
    }
}

export default translate()(
    inject('browser', 'env')(observer(BannerNotification))
);
