import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import cn from 'classnames';
import PropTypes from 'prop-types';
import BrowserStore from '../../browser/stores/BrowserStore';
import { EnvStoreProps, ResearchStoreProp } from '../../stores/props';
import UDExitIsolation from '../UD/Fragments/UDExitIsolation';
import TopBarUrl from '../TopBarUrl/TopBarUrl';
import TopBarAdBlock from '../TopBarAdBlock/TopBarAdBlock';
import TopBarNewTab from '../TopBarNewTab/TopBarNewTab';
import TopBarLogo from '../TopBarLogo/TopBarLogo';
import TopBarMenuButton from '../TopBarMenuButton/TopBarMenuButton';
import TopBarResearchLocation from '../TopBarResearchLocation/TopBarResearchLocation';
import TopBarResearchUA from '../TopBarResearchUA/TopBarResearchUA';
import BannerNotification from '../Notification/BannerNotification';
import './TopBarAlternative.css';
import './../TopBarCaret.css';

const altTopBarBlock = b.lock('TopBarAlternative');

class TopBarAlternative extends Component {
    static propTypes = {
        browser: BrowserStore.PropType.isRequired,
        env: EnvStoreProps,
        isWizard: PropTypes.bool,
        onSubmit: PropTypes.func,
        research: ResearchStoreProp,
        onRefresh: PropTypes.func,
    };
    static defaultProps = {
        onRefresh: () => {
        },
        onSubmit: () => {
        },
    };

    barRef = React.createRef();

    refreshMinified = (leaveExpanded) => () => {
        if (!this.props.browser.isTopBarMinimizable()) {
            return;
        }
        if (!this.barRef.current) {
            return;
        }
        const active = this.barRef.current.contains(document.activeElement);
        const result = leaveExpanded || active;
        this.props.browser.topBarNeedsMinimization.setValue(result);
    }

    getResearchBtnProps = (active) => {
        const { browser, onRefresh } = this.props;
        const minified = browser.isTopBarMinificationAllowed();

        return {
            className: altTopBarBlock('Shortcut'),
            btnProps: {
                className: altTopBarBlock('ResearchButton', { minified }),
                iconRightClassName: b.lock('TopBarCaret')({ active, minified }),
            },
            onRefresh,
        };
    }

    getShortcutBtnProps = () => {
        const { browser, isWizard } = this.props;
        const isMinified = browser.isTopBarMinificationAllowed();

        return {
            isWizard,
            className: altTopBarBlock('Shortcut', { hidden: isMinified }),
            btnProps: {
                className: altTopBarBlock('IconButton'),
            },
        };
    }

    simulateUnfocusedMousedOverTopBar = () => {
        document.activeElement.blur();
        this.refreshMinified(true)();
    }

    render() {
        const { browser, research, env, isWizard, onRefresh } = this.props;

        const isMinified = browser.isTopBarMinificationAllowed();
        const state = browser.getVisualState();

        return <div
            ref={this.barRef}
            className={cn('notranslate', altTopBarBlock({
                research: research.researchMode.isEnabled,
                minified: isMinified,
                noTransition: browser.isHomePage,
                isWizard,
                state,
                homepage: browser.isHomePage,
                mobile: env.screen.isMobile,
            }))}
            onClick={() => {
                browser.topBarMenu.close();
            }}
            onFocus={this.refreshMinified(true, 'onFocus')}
            onBlur={this.refreshMinified(false, 'onBlur')}
            onMouseEnter={this.refreshMinified(true, 'onMouseEnter')}
            onMouseLeave={this.refreshMinified(false, 'onMouseLeave')}
        >
            <div className={altTopBarBlock('innerdiv', { mobile: env.screen.isMobile, minified: isMinified })}>
                <TopBarLogo isWizard={isWizard} />

                <TopBarUrl
                    className={altTopBarBlock('Url', { minified: isMinified, mobile: env.screen.isMobile })}
                    onSubmit={this.props.onSubmit}
                    isWizard={isWizard}
                    isMinified={isMinified}
                />

                {!env.screen.isMobile
                && !env.screen.isTablet
                && research.researchMode.isEnabled
                && !isWizard
                    ? <Fragment>
                        <TopBarResearchLocation {...this.getResearchBtnProps(research.locationMenu.isOpen)} />
                        <TopBarResearchUA {...this.getResearchBtnProps(research.userAgentMenu.isOpen)} />
                    </Fragment>
                    : null
                }

                {!env.isUrlIsolation && !env.isSaasIsolation && !env.screen.isMobile ? <Fragment>
                    <TopBarNewTab {...this.getShortcutBtnProps()} />
                    {browser.adBlockSwitchEnabled ?
                        <TopBarAdBlock
                            onRefresh={onRefresh}
                            {...this.getShortcutBtnProps()}
                        /> : null}
                </Fragment> : null}

                {env.isUrlIsolation && browser.canExitIsolation()
                    ? <UDExitIsolation
                        className={altTopBarBlock('Shortcut', { minified: isMinified, type: 'exit' })}
                    />
                    : null}

                <TopBarMenuButton
                    className={cn(altTopBarBlock('Shortcut', { minified: isMinified }), altTopBarBlock('MenuBtn'))}
                    isWizard={isWizard}
                    btnProps={{
                        className: altTopBarBlock('IconButton'),
                        iconClassName: altTopBarBlock('MenuIcon'),
                    }}
                    // TODO: Perhaps worth adding for other buttons as well? Not too clean of a pattern though
                    onClose={this.simulateUnfocusedMousedOverTopBar}
                />
            </div>
            {!browser.isMinimized() ?
                <BannerNotification />
                : null}
        </div>;
    }
}

export default inject('browser', 'research', 'history', 'env', 'research')(observer(TopBarAlternative));

