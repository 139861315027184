import React, { Component } from 'react';
import { observer } from 'mobx-react';
import b from 'b_';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RadioButtonStore from '../../stores/RadioButtonStore';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './FuseTabs.css';
import './FuseTabs.new.css';

let block = b.lock('FuseTabs');
const newBlock = b.lock('Fuse5Tabs');

class FuseTabs extends Component {
    static propTypes = {
        store: RadioButtonStore.PropType.isRequired,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
            focus: PropTypes.bool,
            hover: PropTypes.bool,
            disabled: PropTypes.bool,
            qa: PropTypes.string,
        })).isRequired,
        onTabChange: PropTypes.func,
        containerClassName: PropTypes.string,
    };

    state = {
        focus: '',
    };

    constructor(props) {
        super(props);
        block = isFuse5Enabled() ? newBlock : block;
    }

    onClickHandler = (value) => (event) => {
        event.preventDefault();
        if (this.props.onTabChange && !this.props.store.equals(value)) {
            this.props.onTabChange(value);
        }
        this.props.store.setValue(value);
        if (event.pageX !== 0 && event.pageY !== 0) {
            this.setState({ focus: value });
            event.target.blur();
        }
    };

    render() {
        const {
            store,
            tabs,
            className,
            containerClassName
        } = this.props;

        return <div className={cn(block(), className, containerClassName)}>{
            tabs.map(({ label, value, focus, disabled, hover, qa, ...inputProps }) => {
                const checked = store.isChecked(value);
                const isFocus = this.state.focus === value;

                return <button
                    key={'tab-' + value}
                    className={cn(block('TabButton', {
                        checked,
                        disabled,
                        focused: focus === undefined ? isFocus : focus,
                        hover,
                        selected: checked,
                    }), className)}
                    onClick={this.onClickHandler(value)}
                    onFocus={() => this.setState({ focus: value })}
                    onBlur={() => this.setState({ focus: '' })}
                    {...qa ? { 'data-qa': qa } : {}}
                    {...inputProps}
                >{label}
                </button>;
            })}</div>;
    }
}

export default (observer(FuseTabs));
