import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import Icon from './Icon/Icon';
import { isFuse5Enabled } from '../fuseFeatureFlag';
import './Button.css';
import './Button.new.css';

let block = b.lock('Button');
const newblock = b.lock('Fuse5Button');

export function Loader({ className }) {
    block = isFuse5Enabled() ? newblock : block;
    return <svg
        className={cn(block('Loader'), className)}
        viewBox="0 0 66 66"
        aria-label="Loading indicator"
    >
        <circle
            className={block('LoaderPath')}
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
        />
    </svg>;
}

// TODO: remove raised/primary/white/simple etc
// TODO: fullWidth - default, new prop "inline"
export default function Button(props) {
    block = isFuse5Enabled() ? newblock : block;
    const {
        loading, raised, primary, white, red,
        primaryGreen,
        secondaryWhite,
        closeOrange,
        closeBox,
        href, children, className,
        icon,
        noCenter,
        fullWidth,
        iconClassName,
        hideOverflowed,
        buttonRef,
        ...otherProps
    } = props;
    const finalClassName = cn(block({
        loading,
        raised,
        primary,
        primaryGreen,
        secondaryWhite,
        closeOrange,
        closeBox,
        red,
        white,
        simple: !raised,
        withIcon: !!icon,
        iconOnly: !!icon && !children,
        fullWidth,
        hideOverflowed,
    }), className);
    const content = <Fragment>
        {noCenter ? null : <div className={block('Space')} />}
        {loading ? <Loader /> : null}
        {icon ? <Icon type={icon} className={cn(block('Icon'), iconClassName)} /> : null}
        <div className={block('Content')}>{children}</div>
        {noCenter ? null : <div className={block('Space')} />}
    </Fragment>;

    if (href) {
        return <a
            className={finalClassName}
            href={href}
            ref={buttonRef}
            {...otherProps}
        >{content}</a>;
    }

    return <button
        className={finalClassName}
        type="button"
        ref={buttonRef}
        {...otherProps}
    >{content}</button>;
}

Button.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    raised: PropTypes.bool,
    primary: PropTypes.bool,
    primaryGreen: PropTypes.bool,
    secondaryWhite: PropTypes.bool,
    closeOrange: PropTypes.bool,
    closeBox: PropTypes.bool,
    red: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    icon: PropTypes.oneOf(Object.keys(Icon.codes)),
    noCenter: PropTypes.bool,
    fullWidth: PropTypes.bool,
    iconClassName: PropTypes.string,
    hideOverflowed: PropTypes.bool,
    buttonRef: PropTypes.func,
};
