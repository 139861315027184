import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import b from 'b_';
import { Key } from '../../mirror/userInput';
import FuseTextfield from './FuseTextfield';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './FuseSearchField.css';

const block = b.lock('FuseSearchField');

class FuseSearchField extends Component {
    static propTypes = {
        ...FuseTextfield.PropType,
        placeholder: PropTypes.string,
    };

    onClear = () => {
        this.props.store.setValue('');
    };

    onKeyDown = (event) => {
        if (event.keyCode === Key.ESC) {
            this.onClear();
            event.stopPropagation();
        }
    };

    render() {
        const { store, ...otherProps } = this.props;

        return <FuseTextfield
            {...otherProps}
            store={store}
            leftIcon="search"
            rightIcon={isFuse5Enabled() ? 'close' : 'cancel'}
            rightIconClassName={block('Clear', { hidden: !store.value })}
            onKeyDown={this.onKeyDown}
            onRightIconClick={this.onClear}
        />;
    }
}

export default observer(FuseSearchField);
