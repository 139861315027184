import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import b from 'b_';
import { isFuse5Enabled } from '../../../fuseFeatureFlag';
import './FuseTooltip.css';
import './FuseTooltip.new.css';

let block = b.lock('FuseTooltip');
const newBlock = b.lock('Fuse5Tooltip');

class FuseTooltip extends Component {
    static propTypes = {
        className: PropTypes.string,
        content: PropTypes.node,
        position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        inline: PropTypes.bool,
    };

    static defaultProps = {
        position: 'top'
    };

    state = {
        positionStyle: null,
        manuallyDisplay: false,
    };

    constructor(props) {
        super(props);
        block = isFuse5Enabled() ? newBlock : block;
    }

    componentDidMount() {
        document.addEventListener('scroll', this.updatePosition, true);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.updatePosition, true);
    }

    onTriggerRef = (node) => {
        this.trigger = node;
    };

    onTooltipRef = (node) => {
        this.tooltip = node;
    };

    updatePosition = () => {
        const triggerRect = this.trigger.getBoundingClientRect();
        const tooltipRect = this.tooltip.getBoundingClientRect();

        let top;
        let left;
        if (this.props.position === 'top' || this.props.position === 'bottom') {
            left = triggerRect.left - (tooltipRect.width / 2) + (triggerRect.width / 2);
        }
        if (this.props.position === 'top') {
            top = triggerRect.top - tooltipRect.height;
        }
        if (this.props.position === 'bottom') {
            top = triggerRect.bottom;
        }
        if (this.props.position === 'left' || this.props.position === 'right') {
            top = triggerRect.top + (triggerRect.height / 2) - (tooltipRect.height / 2);
        }
        if (this.props.position === 'left') {
            left = triggerRect.left - tooltipRect.width;
        }
        if (this.props.position === 'right') {
            left = triggerRect.right;
        }

        this.setState({
            positionStyle: {
                top,
                left,
            }
        });
    };

    render() {
        const { content, className, position, inline, children } = this.props;
        const { positionStyle, manuallyDisplay } = this.state;

        return <div className={cn(block({ inline }), className)}>
            <div
                className={block('TooltipTrigger', { manuallyDisplay })}
                ref={this.onTriggerRef}
                onMouseMoveCapture={this.updatePosition}
            >
                {children}
            </div>

            <div
                className={block('TooltipContainer', {
                    position,
                    positionStyleFixed: !!positionStyle
                })}
                ref={this.onTooltipRef}
                style={positionStyle}
            >
                {content}
                {!isFuse5Enabled() ? <span className={block('TooltipTriangle', { position })} /> : null }
            </div>
        </div>;
    }

    show = () => {
        if (!this.state.manuallyDisplay) {
            document.addEventListener('mousemove', this.hide, true);
            document.addEventListener('mousedown', this.hide, true);
            this.setState({
                manuallyDisplay: true,
                positionStyle: null
            });
            setTimeout(() => {
                this.updatePosition();
                this.updatePosition(); // fix the position
            });
        }
    };

    hide = () => {
        if (this.state.manuallyDisplay) {
            this.setState({ manuallyDisplay: false });
            document.removeEventListener('mousemove', this.hide, true);
            document.removeEventListener('mousemove', this.hide, true);
        }
    };

    toggle() {
        if (!this.state.manuallyDisplay) {
            this.show();
        } else {
            this.hide();
        }
    }
}

export default FuseTooltip;
