import PropTypes from 'prop-types';
import React from 'react';
import b_ from 'b_';
import { observer } from 'mobx-react';
import cn from 'classnames';
import FlagStore from '../../stores/FlagStore';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './Toggle.css';
import './Toggle.new.css';

let block = b_.lock('Toggle');
const newBlock = b_.lock('Fuse5Toggle');

export default function Toggle(props) {
    const {
        onLabel,
        offLabel,
        className,
        ...inputProps
    } = props;

    block = isFuse5Enabled() ? newBlock : block;
    return <label className={cn(block(), className)}>
        <input
            className={block('Input')}
            type="checkbox"
            {...inputProps}
        />
        {offLabel ? <div className={block('offLabel')}>{offLabel}</div> : null }
        <div className={block('Track')} />
        {onLabel ? <div className={block('onLabel')}>{onLabel}</div> : null }
    </label>;
}

Toggle.propTypes = {
    onLabel: PropTypes.node,
    offLabel: PropTypes.node,
};

export const ObservableToggle = observer(({ store, ...props }) => <Toggle
    checked={store.isTrue}
    onChange={store.toggle}
    {...props}
/>);

ObservableToggle.propTypes = {
    store: FlagStore.PropType.isRequired,
    ...Toggle.propTypes,
};
