import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import b from 'b_';
import FlagStore from '../../stores/FlagStore';
import BoolStore from '../../stores/helpers/BoolStore';
import Icon from '../Icon/Icon';
import { Key } from '../../mirror/userInput';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './Accordion.css';
import './Accordion.new.css';

const oldBlock = b.lock('Accordion');
const newBlock = b.lock('AccordionFuse5');


let id = 0;
const getId = () => {
    id += 1;
    return 'Accordion_' + id;
};

class Accordion extends Component {
    static propTypes = {
        label: PropTypes.node.isRequired,
        labelWrapperClassName: PropTypes.string,
        labelClassName: PropTypes.string,
        iconClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        defaultExpanded: PropTypes.bool,
        flagStore: PropTypes.oneOfType([FlagStore.PropType.isRequired, BoolStore.PropTypes.isRequired]),
        preventCollapse: PropTypes.bool,
        disableFocus: PropTypes.bool,
        usePlusIcon: PropTypes.bool,
        qa: PropTypes.string,
    };

    state = {
        isExpanded: false
    };

    constructor(props) {
        super(props);

        const { flagStore, defaultExpanded, preventCollapse } = props;

        this.state = {
            isExpanded: flagStore ? flagStore.getValue() : !!(defaultExpanded || preventCollapse),
        };
    }

    id = getId();

    inputRef = React.createRef();
    labelRef = React.createRef();

    onLabelKeydown = (event) => {
        const { keyCode, target } = event;
        if (!this.labelRef.current || this.labelRef.current !== target) {
            return;
        }
        if (keyCode === Key.ENTER || keyCode === Key.SPACE) {
            if (this.inputRef.current) {
                event.preventDefault();
                this.inputRef.current.click();
            }
        }
    };

    onCheckboxChanged = (evt) => {
        this.setState({ isExpanded: !!evt.currentTarget.checked });
    };

    render() {
        const {
            className,
            label,
            labelWrapperClassName,
            labelClassName,
            iconClassName,
            contentClassName,
            defaultExpanded,
            flagStore,
            preventCollapse,
            disableFocus,
            children,
            usePlusIcon,
            qa,
            ...otherProps
        } = this.props;

        const block = isFuse5Enabled() ? newBlock : oldBlock;

        const inputProps = flagStore ? {
            checked: flagStore.getValue(),
            onChange: flagStore.toggle,
        } : {
            defaultChecked: defaultExpanded || preventCollapse,
        };

        const contentId = this.id + '_Content';
        const labelId = this.id + '_Label';

        return <div className={cn(block(), className)} {...otherProps}>
            <input
                aria-hidden="true"
                className={block('Checkbox')}
                type="checkbox"
                id={this.id}
                disabled={preventCollapse}
                ref={this.inputRef}
                onChange={this.onCheckboxChanged}
                {...inputProps}
            />
            <label
                id={labelId}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                aria-controls={contentId}
                aria-expanded={this.state.isExpanded}
                className={cn(block('LabelWrapper'), labelWrapperClassName)}
                tabIndex={disableFocus ? -1 : 0}
                onKeyDown={this.onLabelKeydown}
                ref={this.labelRef}
                htmlFor={this.id}
                {...qa ? { 'data-qa': qa + '-label' } : {}}
            >
                <div
                    className={cn(block('Label', { expandOnLeft: usePlusIcon }), labelClassName)}
                    tabIndex={-1}
                >
                    {usePlusIcon && !preventCollapse ?
                        <Icon className={block('Toggle')} type={!inputProps?.checked ? 'add' : 'minus'} />
                        : null
                    }
                    {label}
                    {preventCollapse || usePlusIcon ? null : <Fragment>
                        <Icon
                            className={cn(block('Expand'), iconClassName)}
                            type="keyboard_arrow_down"
                        />
                        <Icon
                            className={cn(block('Collapse'), iconClassName)}
                            type="keyboard_arrow_up"
                        />
                    </Fragment>}
                </div>
            </label>
            <div
                className={block('ContentWrapper')}
                id={contentId}
                role="region"
                aria-labelledby={labelId}
                hidden={!this.state.isExpanded}
            >
                <div className={cn(block('Content'), contentClassName)}>
                    {children}
                </div>
            </div>
        </div>;
    }
}

export default observer(Accordion);
