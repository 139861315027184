import React, { Component, Fragment } from 'react';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import { FixedSizeList } from 'react-window';
import { IS_BROWSER_IE11, IS_BROWSER_ON_MAC } from '../../env';
import FuseSelectStore from '../../stores/FuseSelectStore';
import makeElementId from '../../makeElementId';
import { Key } from '../../mirror/userInput';
import Icon from '../Icon/Icon';
import UDSpinner from '../UD/Fragments/UDSpinner';
import FuseInfoTooltip from './FuseTooltip/FuseInfoTooltip';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './FuseSelect.css';
import './FuseSelect.new.css';

let block = b.lock('FuseSelect');
const newBlock = b.lock('Fuse5FuseSelect');

const OPTION_ALL_INDEX = -1;
const SCROLLABLE_LIST_MIN_ITEMS_COUNT = 6;

export default class FuseSelect extends Component {
    static propTypes = {
        selectLabel: PropTypes.string,
        searchLabel: PropTypes.string,
        placeholder: PropTypes.node,
        options: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.OptionPropType),
        filteredOptions: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.OptionPropType),
        selectedValues: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.ValuePropType),
        fullWidth: PropTypes.bool,
        filter: PropTypes.string,
        searchable: PropTypes.bool,
        selectAllEnabled: PropTypes.bool,
        singleSelection: PropTypes.bool,
        loading: PropTypes.bool,
        lowOpacity: PropTypes.bool,
        actionStyle: PropTypes.bool,
        actionStylePlaceHolder: MobxPropTypes.objectOrObservableObject,
        wordBreakAll: PropTypes.bool,
        containerClassName: PropTypes.string,
        hintClassName: PropTypes.string,
        labelClassName: PropTypes.string,
        noOptionsLabel: PropTypes.string,
        optionIconClassName: PropTypes.string,
        hint: PropTypes.string,
        error: PropTypes.string,
        disabled: PropTypes.bool,
        onSelect: PropTypes.func,
        onFilter: PropTypes.func,
        renderOptionLabel: PropTypes.func,
        sortFactory: PropTypes.func,
    };

    state = {
        open: false,
        focus: false,
        hovered: null,
        arrowSearching: false,
        selectedValues: [],
        sortedSelectedValues: [],
        enabledDisplayedOptions: [],
        selectedValuesOfEnabledDisplayedOptions: [],
    };

    static defaultProps = {
        options: [],
        filteredOptions: [],
        lowOpacity: false,
        onSelect: () => null,
        onFilter: () => null,
    };

    optionRefs = [];
    buttonRef = React.createRef();

    labelId = makeElementId('fuseSelectLabel');
    placeholderId = makeElementId('fuseSelectPlaceholder');
    optionListId = makeElementId('fuseSelectOptionList');
    optionIdPrefix = makeElementId('fuseSelectOption');
    getOptionId = (id) => this.optionIdPrefix + '_' + id;

    constructor(props) {
        super(props);
        block = isFuse5Enabled() ? newBlock : block;
    }

    componentDidMount() {
        if (this.element) {
            this.element.addEventListener('keydown', this.handleKeyDown);
        }
        this.onUpdate(this.props);
    }

    componentWillUnmount() {
        if (this.element) {
            this.element.removeEventListener('keydown', this.handleKeyDown);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.onUpdate(nextProps);
    }

    onUpdate = (props) => {
        if (props.filteredOptions.length !== this.props.filteredOptions.length) {
            this.setState({ hovered: null });
        }
        const options = props.filter ? props.filteredOptions : props.options;
        if (options) {
            this.setState({
                enabledDisplayedOptions: options.filter((option) => !option.disabled && !option.hidden),
            });
        }
        const fixedSelectedValue = !options || !props.selectedValues ? props.selectedValues :
            props.selectedValues.filter((value) =>
                (!props.singleSelection || !value.hidden) && !!props.options.find((option) => option.value === value));
        if (fixedSelectedValue) {
            this.setState({
                selectedValues: fixedSelectedValue,
                sortedSelectedValues: this.getSortedSelectedOptions(fixedSelectedValue)
            });
        }
        if (options && fixedSelectedValue) {
            this.setState({
                selectedValuesOfEnabledDisplayedOptions: fixedSelectedValue.filter((value) => {
                    const checkedOption = options.find((option) => option.value === value);
                    return checkedOption && !checkedOption.disabled && !checkedOption.hidden;
                }),
            });
        }
    };

    onRef = (element) => {
        this.element = element;
    };

    onOptionRef = (element, index) => {
        this.optionRefs[index] = element;
    };

    onHover = (value) => {
        this.setState({ hovered: value });
    };

    onSearchInputRef = (element) => {
        this.searchInputElement = element;
    };

    toggleList = (event) => {
        if (this.props.disabled || (this.searchInputElement && this.searchInputElement.contains(event.target))) {
            return;
        }
        const open = !this.state.open;
        this.setState({ open, focus: true, hovered: null, arrowSearching: false });
        if (open && this.props.searchable) {
            this.onClearSearch();
        }
    };


    handleKeyDown = (event) => {
        const { focus, open, hovered, selectedValues } = this.state;
        const { filter, options, filteredOptions, searchable, singleSelection, selectAllEnabled } = this.props;
        const fixedOptions = filter ? filteredOptions : options;

        if (!focus) {
            return;
        }

        const hoveredOption = hovered != null && hovered !== OPTION_ALL_INDEX ? fixedOptions[hovered] : null;

        if (event.keyCode === Key.SPACE || event.keyCode === Key.ENTER) {
            if (!open || hovered == null) {
                this.toggleList(event);
            }

            if (open && hovered != null) {
                if (hovered === OPTION_ALL_INDEX) {
                    event.stopPropagation();
                    event.preventDefault();

                    this.onSelectAll();
                } else if (!hoveredOption.disabled && !hoveredOption.hidden) {
                    event.stopPropagation();
                    event.preventDefault();

                    const selectedIndex =
                        hoveredOption ? selectedValues.findIndex((value) => hoveredOption.value === value) : null;
                    this.onSelect(hoveredOption.value, selectedIndex, true);
                }
                if (event.keyCode === Key.ENTER || (searchable && singleSelection)) {
                    if (searchable) {
                        this.searchInputElement.blur();
                        this.focus();
                    } else {
                        this.toggleList(event);
                    }
                }
            }
        }

        if (event.keyCode === Key.DOWN || event.keyCode === Key.UP) {
            this.setState({ arrowSearching: true });
            event.stopPropagation();
            event.preventDefault();

            const singleSelectedIndex = singleSelection && selectedValues.length > 0 ?
                fixedOptions.findIndex((option) => option.value === selectedValues[0]) : null;

            if (!open) {
                this.toggleList(event);
            } else if (hovered != null) {
                if (event.keyCode === Key.DOWN) {
                    this.hoverFirstAvailableOption(hovered + 1);
                }
                if (event.keyCode === Key.UP) {
                    this.hoverFirstAvailableOption(hovered - 1, true);
                }
            } else if (singleSelectedIndex != null) {
                this.onHover(singleSelectedIndex);
            } else if (event.keyCode === Key.DOWN) {
                this.hoverFirstAvailableOption(selectAllEnabled ? OPTION_ALL_INDEX : 0);
            }

            let optionToScroll = null;
            if (hovered != null && hovered !== OPTION_ALL_INDEX) {
                optionToScroll = hovered;
            } else if (singleSelectedIndex != null) {
                optionToScroll = singleSelectedIndex;
            }
            if (optionToScroll != null) {
                this.optionRefs[optionToScroll].scrollIntoView(event.keyCode === Key.DOWN);
            }
        }

        if (event.keyCode === Key.ESC && open) {
            event.stopPropagation();
            event.preventDefault();

            this.toggleList(event);
        } else if (this.state.arrowSearching && event.keyCode !== Key.DOWN && event.keyCode !== Key.ESC
            && event.keyCode !== Key.UP && event.keyCode !== Key.SPACE) {
            this.setState({ arrowSearching: false, hovered: null });
            event.stopPropagation();
            event.preventDefault();
        }
    };


    hoverFirstAvailableOption = (fromIndex, directionUp) => {
        const { filter, options, filteredOptions, selectAllEnabled } = this.props;

        if ((fromIndex === OPTION_ALL_INDEX && !selectAllEnabled) ||
            (fromIndex === OPTION_ALL_INDEX - 1 && selectAllEnabled)) {
            this.setState({ arrowSearching: false, hovered: null });
            return;
        }

        if (selectAllEnabled && fromIndex === OPTION_ALL_INDEX) {
            this.setState({ hovered: OPTION_ALL_INDEX });
            return;
        }

        const fixedOptions = filter ? filteredOptions : options;
        const diff = directionUp ? -1 : 1;

        for (let index = fromIndex; directionUp ? index >= 0 : index < fixedOptions.length; index += diff) {
            if (!fixedOptions[index].disabled && !fixedOptions[index].hidden) {
                this.setState({ hovered: index });
                return;
            }
        }
        if (directionUp && selectAllEnabled) {
            this.setState({ hovered: OPTION_ALL_INDEX });
        }
    };

    onSelect = (value, selectedIndex, notClosed) => {
        if (this.props.singleSelection) {
            this.setState({
                selectedValues: [value],
                sortedSelectedOptions: [value],
                selectedValuesOfEnabledDisplayedOptions: [value],
                ...notClosed ? {} : { open: false, hovered: null }
            }, () => {
                this.focus();
                this.props.onSelect(this.state.selectedValues);
            });

            return;
        }

        if (selectedIndex != null && selectedIndex >= 0) {
            this.state.selectedValues.splice(selectedIndex, 1);
            this.state.sortedSelectedValues = this.getSortedSelectedOptions(this.state.selectedValues);
            const displayedSelectedIndex = this.state.selectedValuesOfEnabledDisplayedOptions.indexOf(value);
            if (displayedSelectedIndex >= 0) {
                this.state.selectedValuesOfEnabledDisplayedOptions.splice(displayedSelectedIndex, 1);
            }
        } else {
            this.state.selectedValues.push(value);
            this.state.sortedSelectedValues = this.getSortedSelectedOptions(this.state.selectedValues);
            this.state.selectedValuesOfEnabledDisplayedOptions.push(value);
        }

        this.setState({
            selectedValues: [...this.state.selectedValues],
            sortedSelectedValues: [...this.state.sortedSelectedValues],
            selectedValuesOfEnabledDisplayedOptions: [...this.state.selectedValuesOfEnabledDisplayedOptions],
        }, () => this.props.onSelect(this.state.selectedValues));
    };

    getSortedSelectedOptions = (selectedValues) => {
        const { sorterFactory, options } = this.props;
        return selectedValues.sort(sorterFactory?.(options) ?? ((valueA, valueB) => {
            const optionA = options.find((currentOption) => currentOption.value === valueA);
            const optionB = options.find((currentOption) => currentOption.value === valueB);
            const labelA = ((optionA && optionA.label) || valueA).toUpperCase();
            const labelB = ((optionB && optionB.label) || valueB).toUpperCase();
            return labelA < labelB ? -1 : 1;
        }));
    }

    onSelectAll = () => {
        const { enabledDisplayedOptions } = this.state;
        let { selectedValues, selectedValuesOfEnabledDisplayedOptions } = this.state;
        let sortedSelectedValues = [];
        if (selectedValuesOfEnabledDisplayedOptions.length < enabledDisplayedOptions.length) {
            selectedValues = [
                ...selectedValues,
                ...enabledDisplayedOptions
                    .filter((option) => selectedValuesOfEnabledDisplayedOptions.indexOf(option.value) < 0)
                    .map((option) => option.value),
            ];
            sortedSelectedValues = this.getSortedSelectedOptions(selectedValues);
            selectedValuesOfEnabledDisplayedOptions = enabledDisplayedOptions.map((option) => option.value);
        } else {
            selectedValues =
                selectedValues.filter((value) => selectedValuesOfEnabledDisplayedOptions.indexOf(value) < 0);
            sortedSelectedValues = this.getSortedSelectedOptions(selectedValues);
            selectedValuesOfEnabledDisplayedOptions = [];
        }
        this.setState({ selectedValues, sortedSelectedValues, selectedValuesOfEnabledDisplayedOptions });
        this.props.onSelect(selectedValues);
    };

    onSearch = (event) => {
        this.props.onFilter(event.nativeEvent);
    };

    onClearSearch = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        window.requestAnimationFrame(() => {
            if (this.searchInputElement) {
                this.searchInputElement.value = '';
                this.props.onFilter({ target: this.searchInputElement });
                this.searchInputElement.focus();
            }
        });
    };

    renderCheckbox = (checked) => {
        if (checked) {
            return <Icon className={block('CheckboxIconChecked')} type="check_box" />;
        }
        return <Icon className={block('CheckboxIconNotChecked')} type="check_box_outline_blank" />;
    };

    onFocusChange = (event, focus) => {
        let relatedTarget = event.nativeEvent.relatedTarget;
        if (IS_BROWSER_IE11 && relatedTarget === null) {
            relatedTarget = document.activeElement;
        }
        if (!this.element ? relatedTarget === this.searchInputElement :
            this.element !== relatedTarget && this.element.contains(relatedTarget)) {
            return;
        }
        this.setState({ focus, open: false, hovered: null });
    };

    onOptionFocus = (optionIndex) => {
        this.setState({ hovered: optionIndex });
    }

    onSearchInputFocusChange = (event, focus) => {
        event.preventDefault();
        event.stopPropagation();

        let relatedTarget = event.nativeEvent.relatedTarget;
        if (IS_BROWSER_IE11 && relatedTarget === null) {
            relatedTarget = document.activeElement;
        }
        if (this.element && this.element.contains(relatedTarget)) {
            return;
        }
        if (!focus) {
            this.setState({ focus, open: false, hovered: null });
        }
    };

    renderOption = (option, optionIndex, virtualListStyle) => {
        const {
            hovered,
            selectedValuesOfEnabledDisplayedOptions,
            enabledDisplayedOptions,
            selectedValues
        } = this.state;

        if (optionIndex === OPTION_ALL_INDEX) {
            const visible = enabledDisplayedOptions.length <= 100;
            const disabled = enabledDisplayedOptions.length === 0;
            const selected =
                !disabled && selectedValuesOfEnabledDisplayedOptions.length === enabledDisplayedOptions.length;

            return (visible ?
                <div
                    id={this.getOptionId(optionIndex)}
                    className={block('Option', { selectAll: true, disabled, hover: hovered === OPTION_ALL_INDEX })}
                    onFocus={() => this.onOptionFocus(OPTION_ALL_INDEX)}
                    onMouseEnter={() => this.onHover(OPTION_ALL_INDEX)}
                    onMouseLeave={() => this.onHover(null)}
                    onClick={this.onSelectAll}
                    role="option"
                    aria-selected={selected}
                    tabIndex={0}
                >
                    {this.renderCheckbox(selected)}
                    <span className={block('OptionLabel')}>Select All ({enabledDisplayedOptions.length})</span>
                </div> : null
            );
        }

        const { singleSelection, optionIconClassName, wordBreakAll, qa } = this.props;
        const { value, label, icon, info, textIcon, disabled } = option;
        const selectedIndex = selectedValues.findIndex((selectedValue) => value === selectedValue);
        const isOptionSelected = selectedIndex >= 0;

        return <div
            id={this.getOptionId(optionIndex)}
            key={'option-' + value}
            className={block('Option', {
                selected: isFuse5Enabled() ? isOptionSelected && singleSelection : isOptionSelected,
                disabled,
                wordBreakAll,
                hover: hovered === optionIndex
            })}
            onFocus={() => this.onOptionFocus(optionIndex)}
            onMouseEnter={() => this.onHover(optionIndex)}
            onMouseLeave={() => this.onHover(null)}
            ref={(element) => this.onOptionRef(element, optionIndex)}
            onClick={() => !disabled && this.onSelect(value, selectedIndex)}
            role="option"
            tabIndex={0}
            aria-selected={isOptionSelected}
            style={virtualListStyle}
            {...qa ? { 'data-qa': `${qa}-option-${value} ${qa}-option-${label}` } : {}}
        >
            {!singleSelection ? this.renderCheckbox(selectedIndex >= 0) : null}
            {icon ? <Icon
                className={cn(block('OptionIcon'), b(optionIconClassName, { type: option.icon }))}
                type={icon}
            /> : null}
            {textIcon ? <span
                className={cn(block('OptionIcon'), b(optionIconClassName, { type: option.icon }))}
            >{textIcon}</span> : null}
            <span title={label} className={block('OptionLabel', { wordBreakAll })}>{label}</span>
            {info ? <FuseInfoTooltip position={virtualListStyle ? null : 'right'} className={block('OptionInfo')}>
                {info}
            </FuseInfoTooltip> : null}
        </div>;
    };

    itemRenderer = ({ index, style }) => {
        const {
            searchable,
            filter,
            options,
            filteredOptions,
        } = this.props;
        let displayedOptions = searchable && filter ? filteredOptions : options;
        displayedOptions = displayedOptions.filter((option) => !option.hidden);

        return this.renderOption(displayedOptions[index], index, style);
    }

    renderList = () => {
        const {
            searchable,
            filter,
            noOptionsLabel,
            options,
            filteredOptions,
            loading,
        } = this.props;

        if (loading) {
            return <div className={block('OptionList')}>
                <div className={block('SpinnerContainer')}>
                    <UDSpinner type="small" />
                </div>
            </div>;
        }

        let displayedOptions = searchable && filter ? filteredOptions : options;
        displayedOptions = displayedOptions.filter((option) => !option.hidden);

        if (displayedOptions.length === 0) {
            return <div className={block('OptionList')}>
                <div className={block('NoOptionsLabel')}>{noOptionsLabel || 'No options'}</div>
            </div>;
        }

        if (displayedOptions.length < 500) {
            return <div className={block('OptionList')}>
                {displayedOptions.map((option, index) => this.renderOption(option, index))}
            </div>;
        }

        return <FixedSizeList
            className={block('OptionList')}
            height={198}
            itemSize={36}
            itemCount={displayedOptions.length}
            itemData={displayedOptions}
        >{this.itemRenderer}</FixedSizeList>;
    };



    render() {
        const {
            selectLabel,
            searchLabel,
            placeholder,
            placeholderIcon,
            options,
            fullWidth,
            searchable,
            selectAllEnabled,
            containerClassName,
            hintClassName,
            labelClassName,
            hint,
            loading,
            singleSelection,
            error,
            disabled,
            filter,
            optionIconClassName,
            lowOpacity,
            renderOptionLabel,
            actionStyle,
            actionStylePlaceHolder,
            qa,
            wordBreakAll,
        } = this.props;
        const { open, focus, hovered, arrowSearching, selectedValues, sortedSelectedValues } = this.state;
        const singleSelectedOption = singleSelection && selectedValues.length > 0 ?
            options.find((option) => option.value === selectedValues[0]) : null;
        const { label: optionLabel, icon } = singleSelectedOption || {};
        let singleSelectPlaceholderElement = null;
        let iconSpace = false;

        if (singleSelectedOption && !actionStyle) {
            singleSelectPlaceholderElement = <Fragment>
                {icon ? (
                    <Icon className={cn(block('OptionIcon'), b(optionIconClassName, { type: icon }))} type={icon} />
                ) : null}
                {optionLabel}
            </Fragment>;
        } else if (actionStyle) {
            singleSelectPlaceholderElement = actionStylePlaceHolder;
        } else if (placeholderIcon) {
            iconSpace = true;
            singleSelectPlaceholderElement = <Fragment>
                <Icon className={block('PlaceholderIcon')} type={placeholderIcon} />
                {placeholder}
            </Fragment>;
        }
        const showListScrollable = IS_BROWSER_ON_MAC && options.length >= SCROLLABLE_LIST_MIN_ITEMS_COUNT;
        const placeholderElement = singleSelectPlaceholderElement || placeholder || 'Select';
        const selectTitle = selectLabel || placeholder || 'Select';

        return <div
            tabIndex="-1"
            ref={this.onRef}
            onFocus={(event) => this.onFocusChange(event, true)}
            onBlur={(event) => this.onFocusChange(event, false)}
            className={cn(block({ focus, actionStyle, disabled, open, fullWidth, error: !!error }), containerClassName)}
            {...qa ? { 'data-qa': qa } : {}}
        >
            {selectLabel ? <div className={cn(block('Label', { focus, error: !!error }), labelClassName)}>
                {selectLabel}
            </div> : null}
            <div className={block('ControlFrame')}>
                {!singleSelection && selectedValues.length > 0 ? (
                    <div className={block('SelectedValuesContainer', { wordBreakAll })}>
                        {loading ? <UDSpinner
                            className={block('SelectedValuesLoader')}
                            iconClassName={block('Spinner')}
                            type="fuse"
                        /> : sortedSelectedValues.map((value, index) => {
                            const option = options.find((currentOption) => currentOption.value === value);
                            const label = (option && option.label) || value;
                            return (
                                <div
                                    key={'selected-' + value}
                                    className={block('SelectedValue', {
                                        disabled: option.disabled || disabled,
                                        hidden: option.hidden || option.error
                                    })}
                                    onClick={() => !option.disabled && !disabled && this.onSelect(value, index)}
                                    {...qa ? { 'data-qa': `val-${value} lab-${label}` } : {}}
                                >
                                    {option.icon ? <Icon
                                        className={cn(block('SelectedOptionIcon'),
                                            b(optionIconClassName, { type: option.icon }))}
                                        type={option.icon}
                                    /> : null}
                                    {renderOptionLabel ? renderOptionLabel(option) : label}
                                    <Icon className={block('CancelIcon')} type="cancel" />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                <div
                    ref={this.buttonRef}
                    tabIndex={0}
                    role="combobox"
                    aria-controls={this.optionListId}
                    aria-expanded={open}
                    aria-haspopup="listbox"
                    aria-labelledby={this.labelId}
                    aria-activedescendant={open && hovered !== null ? this.getOptionId(hovered) : ''}
                    className={block('SelectButton', { actionStyle, iconSpace })}
                    onClick={this.toggleList}
                    title={selectTitle}
                >
                    {isFuse5Enabled() ? <Icon
                        className={block('ArrowIcon', { open })}
                        type={'keyboard_arrow_down'}
                    /> : null }
                    {!open || !searchable
                        ? (
                            <span className={block('SelectLabel', { placeholder: lowOpacity })}>
                                {placeholderElement}
                            </span>
                        ) : (
                            <Fragment>
                                <Icon className={block('SearchIcon')} type="search" />
                                <input
                                    ref={this.onSearchInputRef}
                                    value={filter}
                                    className={block('SearchInput', { hideCursor: arrowSearching })}
                                    onFocus={(event) => this.onSearchInputFocusChange(event, true)}
                                    onBlur={(event) => this.onSearchInputFocusChange(event, false)}
                                    placeholder={searchLabel || 'Search'}
                                    tabIndex={-1}
                                    onChange={this.onSearch}
                                />
                                {filter && !IS_BROWSER_IE11 ? (
                                    <Icon
                                        className={block('ClearIcon')}
                                        type={isFuse5Enabled() ? 'close' : 'cancel'}
                                        onClick={this.onClearSearch}
                                    />
                                ) : null}
                            </Fragment>
                        )}
                </div>
            </div>
            <div
                className={block('OptionsContainer', { scrollable: showListScrollable })}
                hidden={!open}
                id={this.optionListId}
                role="listbox"
                aria-labelledby={this.labelId}
            >
                {selectAllEnabled && !singleSelection && !loading
                    ? this.renderOption(null, OPTION_ALL_INDEX)
                    : null}
                {this.renderList()}
            </div>
            {(hint || error) ? <div className={cn(block('Hint'), hintClassName)}>{hint || error}</div> : null}
        </div>;
    }

    focus() {
        this.buttonRef?.current?.focus();
    }

    blur() {
        this.buttonRef?.current?.blur();
    }
}

export const ObservableFuseSelect = observer(({ store, ...props }) => <FuseSelect
    ref={store.setRef}
    error={store.error}
    options={store.options}
    disabled={store.disabled.value}
    loading={store.loading.value}
    selectedValues={store.selectedValues}
    onSelect={store.setSelectedValues}
    onFilter={store.filter.onChangeHandler}
    filter={store.filter.value}
    filteredOptions={store.getFilteredOptions()}
    {...props}
/>);

ObservableFuseSelect.propTypes = {
    store: FuseSelectStore.PropType.isRequired,
    ...FuseSelect.propTypes,
};
